@use 'src/src/app/scss/abstracts' as *;

.mobile-navigation {
  $block-name: &;

  position: fixed;
  z-index: $z-index-mobile-navigation;
  top: 0;
  right: 0;
  left: 0;
  max-height: 100%;
  padding: 8px;
  padding-top: 8px;
  transform: translate3d(0, -100%, 0);
  transition:
    transform $transition-slow,
    opacity $transition-slow;
  opacity: 0;
  padding-bottom: 70px;
  overflow-y: auto;

  &__inner {
    padding: 40px 30px;
    padding-top: 160px;
    background: $color-semantic-main;
    border-radius: 6px;
    box-shadow: 0 5px 50px 0 rgb(118 69 255 / 90%);
  }

  &__list-wrap {
    transform: translate3d(0, -20px, 0);
    transition:
      transform $transition-slow,
      opacity $transition-slow;
    transition-delay: 0.2s;
    opacity: 0;
  }

  &__list {
    @include unlist;
    @include reset-text-indents;

    text-align: center;
  }

  &__item {
  }

  &__item-link {
    display: block;
    text-decoration: none;
    color: $color-base-purple-200;
    font-size: 48px;
    font-style: normal;
    font-weight: $font-weight-xl;
    line-height: 51px;
    letter-spacing: -1.8px;
    padding: 12px;
    transition: all $transition-fast;

    &:hover {
      color: $color-semantic-third;
    }

    &:active {
      color: rgba($color-semantic-third, 0.9);
    }

    &--active {
      color: $color-base-gray-500 !important;
      background-color: transparent !important;
    }
  }

  &__button {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    transition: all $transition-slow;

    .button {
      min-height: 51px;

      @include mq($until: sm) {
        justify-content: center;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }
    }
  }

  &__logo {
    display: inline-flex;
    position: absolute;
    top: 13px;
    left: 15px;
    padding: 5px;
  }

  &__locales {
    @include unlist;

    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &__locale {
  }

  &__locale-link {
    display: inline-flex;
    padding: 9px;
    text-decoration: none;
    color: rgba($color-semantic-third, 0.85);
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.48px;
    text-transform: capitalize;
    font-weight: $font-weight-m;

    &--active {
      color: $color-base-gray-500;
    }
  }

  &--opened {
    transform: translate3d(0, 0, 0);
    opacity: 1;

    #{$block-name} {
      &__list-wrap {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      &__button {
      }
    }
  }
}
