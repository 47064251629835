@use 'src/src/app/scss/abstracts' as *;

.spotify-dropdown {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  @include reset-text-indents;

  display: inline-block;
  position: relative;
  z-index: $z-index-dropdown;

  &__body {
    position: absolute;
    z-index: 5;
    top: 100%;
    right: 0;
    min-width: 345px;
    height: 0;
    margin: 0;
    overflow: hidden;
    transform: translateY(10px);
    transition:
      opacity 0.15s ease,
      transform 0.15s ease;
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    padding-top: 10px;

    @include mq($until: sm) {
      right: -83px;
    }
  }

  &__body-inner {
    position: relative;

    // max-height: 75vmin;
    // overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
    font-size: 0;
    line-height: 0;
    border-radius: $border-radius-m;
    overflow: hidden;
  }

  &__toggler {
    display: inline-flex;
  }

  &--is-open {
    display: inline-block;
    position: relative;

    #{$block-name} {
      &__toggler {
      }

      &__body {
        height: auto;
        visibility: visible;
        overflow: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &--is-play {
    .button {
      position: relative;
      overflow: visible !important;
      transition: none !important;
      color: $color-base-purple-500 !important;
      background-color: transparent !important;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        border-width: 2px;
        border-style: solid;
        margin-top: -12.5px;
        margin-left: -12.5px;
        border-radius: 50%;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        opacity: 0;
        animation-name: lds-ripple;
        animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1);

        @include mq($until: lg) {
          margin-top: -17.5px;
          margin-left: -17.5px;
        }
      }

      &::after {
        animation-delay: -2s;
      }
    }
  }
}
